"use client";
// react
import { KeyboardEvent, useState } from "react";

// third party
import { faDog, faBuilding, faAtom } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import SearchInput from "../search-trials-input";

import styles from "./index.module.scss";

const selectIcon = (c: { name: string }) => {
  switch (c.name) {
    case "species":
      return faDog;
    case "organizations":
      return faBuilding;
    case "categories":
      return faAtom;
    default:
      return faDog;
  }
};

const MobileFilter = ({
  filters,
  categories,
  onSearch,
  onUpdate,
  updatedFilters,
  clearFilters,
}: {
  categories: Array<{ id: string; name: string }>;
  filters: {
    [key: string]: Array<{ id: string; name: string }>;
  };
  onSearch: (e: KeyboardEvent<HTMLInputElement>) => void;
  onUpdate: (
    updateOption: "set" | "toggle" | "dispatch" | "reset",
    category?: string,
    filter?: string,
    optional?: boolean,
  ) => void;
  updatedFilters: string[];
  clearFilters: () => void;
}) => {
  const [displayMobileFilters, setDisplayMobileFilters] = useState(false);
  const [totalFilters, setTotalFilters] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const manipulateFilter = (overflow: "hide" | "remove", display: boolean) => {
    const body = document.getElementsByTagName("body").item(0);
    if (body) {
      switch (overflow) {
        case "hide":
          body.style.overflow = "hidden";
          break;
        case "remove":
          body.style.removeProperty("overflow");
      }
    }
    setDisplayMobileFilters(display);
  };

  const filteredFilters = updatedFilters.filter(
    (uf) => !uf.includes("page") && !uf.includes("name"),
  );

  return (
    <div className="d-md-none">
      <SearchInput
        value={searchValue}
        setValue={setSearchValue}
        onKeyDown={onSearch}
        filterIcon={{
          onClick: () => manipulateFilter("hide", true),
          totalFilters,
        }}
      />
      <div
        className={cx(styles["mobile-background-cover"], { [styles.show]: displayMobileFilters })}
        onClick={() => {
          manipulateFilter("remove", false);
          onUpdate("reset");
        }}
      />
      <div
        style={{ bottom: displayMobileFilters ? "0" : "calc(-100vh - 12rem)" }}
        className={styles["mobile-filter"]}
      >
        <div className="d-flex align-items-center flex-column position-relative">
          <div className="d-flex justify-content-between mb-3 w-100">
            <h3>Refine Search</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="cursor--pointer p-1"
              size="lg"
              onClick={() => {
                manipulateFilter("remove", false);
              }}
            />
          </div>
          <div className={styles["filters-container"]}>
            {categories.map((c) => {
              const uppercaseLetterPosition = c.name.search(/[A-Z]/g);
              const fixedName = c.name.replaceAll(/[A-Z]/g, ` ${c.name[uppercaseLetterPosition]}`);

              const categoryName = fixedName[0].toUpperCase() + fixedName.substring(1);

              return (
                <div key={c.id} className="mb-4">
                  <div className={styles.category}>
                    <div className={styles.icon}>
                      <FontAwesomeIcon icon={selectIcon(c)} size="lg" />
                    </div>
                    <h3>{categoryName}</h3>
                  </div>
                  {filters[c.name].map((f) => (
                    <div
                      key={f.id}
                      className={styles.filter}
                      onClick={() => onUpdate("toggle", c.id, f.id)}
                    >
                      <div
                        className={cx(styles.checkbox, {
                          [styles.selected]: filteredFilters.includes(`${c.id}\v${f.id}`),
                        })}
                      >
                        <FontAwesomeIcon icon={faCheck} fontSize="1rem" />
                      </div>
                      <span className="text-style-h6-regular">{f.name}</span>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          <div className={styles["button-container"]}>
            <button
              className={cx("primary text-style-body1-bold py-2 px-3", {
                disabled: filteredFilters.length === 0,
              })}
              disabled={filteredFilters.length === 0}
              onClick={() => {
                onUpdate("dispatch");
                setTotalFilters(filteredFilters.length);
                manipulateFilter("remove", false);
              }}
            >
              Apply Filters{filteredFilters.length > 0 && ` (${filteredFilters.length})`}
            </button>
            <span
              className={cx(styles.clear, "text-style-body1-bold", {
                [styles.disabled]: totalFilters === 0,
              })}
              onClick={() => {
                clearFilters();
                setSearchValue("");
                setTotalFilters(0);
                manipulateFilter("remove", false);
              }}
            >
              Clear All
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilter;
