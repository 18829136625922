import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useState } from "react";

export const useSearchTrials = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const initialFilters = Array.from(searchParams.entries()).map(
    ([category, filter]) => `${category}\v${filter}`,
  );

  const [filters, setFilters] = useState<string[]>(initialFilters);

  function toggle(category: string, filter: string, useDispatch?: boolean) {
    const filterName = `${category}\v${filter}`;
    const newFilters = filters.includes(filterName)
      ? filters.filter((uf) => uf !== filterName)
      : [...filters, filterName];

    if (category !== "page") {
      const oldPage = newFilters.findIndex((f) => f.split("\v")[0] === "page");

      if (oldPage > -1) {
        newFilters.splice(oldPage, oldPage + 1, "page\v1");
      } else {
        newFilters.splice(0, 0, "page\v1");
      }
    }

    setFilters(newFilters);

    if (useDispatch) {
      dispatch(newFilters);
    }
  }

  function set(category: string, filter: string, useDispatch?: boolean) {
    const filterName = `${category}\v${filter}`;
    const newFilters = filters.filter((uf) => uf.split("\v")[0] !== category);

    if (filter) {
      newFilters.push(filterName);
    }

    if (category !== "page") {
      const oldPage = newFilters.findIndex((f) => f.split("\v")[0] === "page");

      if (oldPage > -1) {
        newFilters.splice(oldPage, oldPage + 1, "page\v1");
      } else {
        newFilters.splice(0, 0, "page\v1");
      }
    }

    setFilters(newFilters);

    if (useDispatch) {
      dispatch(newFilters);
    }
  }

  function dispatch(newFilters: string[] = filters) {
    const current = new URLSearchParams();

    newFilters.forEach((uf) => {
      const [c, f] = uf.split("\v");
      if (c === "page") {
        current.set(c, f);
      } else {
        current.append(c, f);
      }
    });

    const search = current.toString();

    router.replace(`${pathname}?${search}`, { scroll: false });
  }

  function reset() {
    setFilters(initialFilters);
  }
  function clear(newFilters: string[] = []) {
    setFilters(newFilters);
    dispatch(newFilters);
  }

  return [filters, toggle, set, dispatch, reset, clear] as const;
};
