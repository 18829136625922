import { KeyboardEvent } from "react";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./index.module.scss";

export default function Search({
  onKeyDown,
  filterIcon,
  setValue,
  value,
}: {
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  setValue: (value: string) => void;
  filterIcon?: { onClick: () => void; totalFilters: number };
}) {
  return (
    <div className={styles["search-input"]}>
      <FontAwesomeIcon icon={faSearch} className={styles.icon} />
      <input
        onChange={(e) => setValue(e.target.value)}
        name="search"
        value={value}
        type="text"
        placeholder="Search for a study"
        onKeyDown={onKeyDown}
      />
      {filterIcon && (
        <div className={styles["filter-icon"]} onClick={() => filterIcon.onClick()}>
          {filterIcon.totalFilters > 0 && (
            <div className={styles["active-filters"]}>{filterIcon.totalFilters}</div>
          )}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="4.73828"
              y1="12.041"
              x2="19.2622"
              y2="12.041"
              stroke="#9EA2A2"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <line
              x1="4.73828"
              y1="17.9219"
              x2="19.2622"
              y2="17.9219"
              stroke="#9EA2A2"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <line
              x1="4.73828"
              y1="6.03125"
              x2="19.2622"
              y2="6.03125"
              stroke="#9EA2A2"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <circle cx="7.97461" cy="6.00977" r="1.5" fill="white" stroke="#9EA2A2" />
            <circle cx="12" cy="12" r="1.5" fill="white" stroke="#9EA2A2" />
            <circle cx="15.957" cy="17.9902" r="1.5" fill="white" stroke="#9EA2A2" />
          </svg>
        </div>
      )}
    </div>
  );
}
