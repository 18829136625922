"use client";

import Image from "next/image";
import { KeyboardEvent } from "react";

// third party
import cx from "classnames";

// components
import { useSearchTrials } from "@/components/pages/studies/search-trials";
import PaginationButtons from "@/components/ui/pagination-buttons";

// private components
import Filter from "../../components/filter";
import MobileFilter from "../../components/mobile-filter";
import sectionStyles from "../section.module.scss";

import styles from "./index.module.scss";

export default function FilterableClientComponent({
  children,
  displayedTrials,
  filters,
  pages,
  total,
  limit,
}: {
  children: React.ReactNode[];
  displayedTrials: number;
  filters: {
    [key: string]: Array<{ id: string; name: string }>;
  };
  pages: number;
  total: number;
  limit: number;
}) {
  const [updatedFilters, toggleFilters, setFilters, dispatchFilters, resetFilters, clearFilters] =
    useSearchTrials();

  const categories = Object.keys(filters)
    .filter(
      (c) => c !== "trialBreed" && c !== "cities" && c !== "trialStatuses" && c !== "interests",
    )
    .map((c) => {
      switch (c) {
        case "organizations":
          return { id: "organizationIds", name: c };
        default:
          return { id: c, name: c };
      }
    });

  const nameSearch = updatedFilters.find((f) => f.includes("name"))?.split("\v")[1];

  const updateFilters = (
    updateOption: "toggle" | "set" | "dispatch" | "reset",
    category?: string,
    filter?: string,
    optional?: boolean,
  ) => {
    if (category && filter) {
      if (updateOption === "toggle") {
        toggleFilters(category, filter, optional);
      } else if (updateOption === "set") {
        setFilters(category, filter, optional);
      }
    } else {
      if (updateOption === "dispatch") {
        dispatchFilters();
      } else if (updateOption === "reset") {
        resetFilters();
      }
    }
  };

  const onSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setFilters("name", e.currentTarget.value, true);
    }
  };

  const clear = () => clearFilters(updatedFilters.filter((uf) => uf.includes("page")));

  const pageParam = updatedFilters.find((uf) => uf.includes("page"));
  const currentPage = pageParam ? Number(pageParam.split("\v")[1]) : 1;

  return (
    <>
      <section className={cx(sectionStyles.section, "py-0 z-2")}>
        <Filter
          updatedFilters={updatedFilters}
          onUpdate={updateFilters}
          clearFilters={clear}
          onSearch={onSearch}
          categories={categories}
          filters={filters}
        />
        <MobileFilter
          updatedFilters={updatedFilters}
          onUpdate={updateFilters}
          clearFilters={clear}
          onSearch={onSearch}
          categories={categories}
          filters={filters}
        />
      </section>
      <section className={sectionStyles.section}>
        {total > 0 ? (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="text-style-h5">All Studies ({total})</h1>
              <div className="d-none d-md-block">
                <PaginationButtons
                  nextDisabled={currentPage === (pages ?? 1)}
                  prevDisabled={currentPage === 1}
                  onNext={() => {
                    updateFilters("set", "page", String(currentPage + 1), true);
                  }}
                  onPrev={() => {
                    updateFilters("set", "page", String(currentPage - 1), true);
                  }}
                >
                  <small className="me-3">
                    Showing {(currentPage - 1) * limit + 1}-
                    {(currentPage - 1) * limit + displayedTrials} of {total} Trials
                  </small>
                </PaginationButtons>
              </div>
            </div>
            <div className="my-4">{children}</div>
            {pages > 1 && (
              <div className="d-none d-md-flex align-items-center justify-content-end">
                <PaginationButtons
                  nextDisabled={currentPage === (pages ?? 1)}
                  prevDisabled={currentPage === 1}
                  onNext={() => {
                    updateFilters("set", "page", String(currentPage + 1), true);
                  }}
                  onPrev={() => {
                    updateFilters("set", "page", String(currentPage - 1), true);
                  }}
                >
                  <small className="me-3">
                    Showing {(currentPage - 1) * limit + 1}-
                    {(currentPage - 1) * limit + displayedTrials} of {total} Trials
                  </small>
                </PaginationButtons>
              </div>
            )}
          </>
        ) : (
          <div className={styles.empty}>
            <Image
              draggable={false}
              src="/trial/cat.svg"
              alt="Animated image of cat with a stack of books"
              height={135}
              width={240}
            />
            <p className="text-style-h6-regular my-3">
              Sorry, we couldn&apos;t find any matches
              {nameSearch && (
                <>
                  {" "}
                  for <span style={{ fontWeight: 700 }}>{nameSearch}</span>
                </>
              )}
            </p>
            {nameSearch && (
              <small className="text-style-body2-regular">
                Please try searching for another term
              </small>
            )}
          </div>
        )}
      </section>
    </>
  );
}
