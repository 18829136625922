"use client";

// next
import Image from "next/image";
import Link from "next/link";
import { useRef, useState } from "react";

// third party
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

// components?
import Html from "@/components/html";

// types
import { Trial } from "@/typedef/trial";

// private
import styles from "./index.module.scss";

const renderBullet = (text: string, slot?: JSX.Element) => (
  <div className="d-flex align-items-center mb-2 white-space--nowrap" key={text}>
    {slot}
    <span className="mx-3">&#x2022;</span>
    <span className="flex-shrink-0 text-style-body2-regular">{text}</span>
  </div>
);

export default function Trials({ trial }: { trial: Trial }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [trialExpanded, setTrialExpanded] = useState(false);

  const {
    category,
    image,
    interventional,
    description,
    nationwide,
    name,
    organization,
    practices,
    slug,
    species,
  } = trial;

  const renderTrialDetails = (className: string) => (
    <div className={className}>
      {renderBullet(
        species.name,
        <div className="text-style-body2-bold ms-3">{organization.name}</div>,
      )}

      {category && renderBullet(category.name)}

      {renderBullet(interventional ? "Interventional" : "Observational")}

      {nationwide
        ? renderBullet("Nationwide")
        : renderBullet(`${practices.length} Location${practices.length === 1 ? "" : "s"}`)}
    </div>
  );

  return (
    <div
      className={cx(styles.trial, { [styles.opened]: trialExpanded })}
      style={{ paddingBottom: trialExpanded ? `${ref.current?.clientHeight}px` : 0 }}
    >
      <div className={styles.information} onClick={() => setTrialExpanded(!trialExpanded)}>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={cx(styles["drop-arrow"], { [styles.open]: trialExpanded })}
        />
        <div className="col-12 col-md-8 col-lg-9">
          <div className="d-flex align-items-center">
            <Image
              draggable={false}
              src={organization.image}
              alt={organization.name}
              height={40}
              width={100}
              className={styles["organization-logo"]}
            />
            {renderTrialDetails("d-none d-xl-flex align-items-center flex-wrap")}
          </div>
          <h2 className="text-style-h5 my-3">{name}</h2>
          {renderTrialDetails("d-flex d-xl-none align-items-center flex-wrap mt-3")}
          <p className={cx(styles.description, "clamp--3 text-style-body1-regular my-3")}>
            {description}
          </p>
          <span className="d-none d-md-inline text-style-body1-bold color--tertiary">
            Read {trialExpanded ? "Less" : "More"}
          </span>
        </div>
        <div className="d-none d-md-block col-md-4 col-lg-3">
          <div className={styles["trial-image"]}>
            <Image src={image} alt={name} fill sizes="20vw" draggable={false} />
          </div>
        </div>
      </div>
      <div className={styles.criteria} ref={ref}>
        <Html className="text-style-body1-regular">{trial.eligibilityCriteria ?? null}</Html>
        <div className="d-flex flex-column flex-sm-row justify-content-sm-end mt-4">
          <Link href={`/studies/${slug}`} className="mb-3 mb-md-0 me-sm-3">
            <button className="secondary text-style-body1-bold py-2 px-3">View All Details</button>
          </Link>
          <Link href={`/studies/${slug}/apply`}>
            <button className="primary text-style-body1-bold py-2 px-3">Apply To Trial</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
