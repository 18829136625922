import Link from "next/link";
import { createElement } from "react";

// third party
import cx from "classnames";
import parse, { domToReact } from "html-react-parser";

import styles from "./index.module.scss";

function Html({
  id = undefined,
  children,
  className = undefined,
  tag = "div",
}: {
  id?: string;
  children: string | null;
  className?: string;
  tag?: string;
}): JSX.Element | null {
  if (typeof children !== "string") {
    return null;
  }

  const html = parse(children, {
    // eslint-disable-next-line
    replace: (node: any) => {
      if (node.name === "a") {
        if (String(node.attribs.href ?? "").startsWith("/")) {
          return <Link {...node.attribs}>{domToReact(node.children)}</Link>;
        }

        if (!node.attribs || !node.attribs.rel) {
          node.attribs.rel = "noreferrer noopener";
        }
      }

      return false;
    },
  });

  return createElement(tag, { id, className: cx(styles.html, className) }, html);
}

export default Html;
