"use client";
// react
import { useState, KeyboardEvent } from "react";

// third party
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

// sibling
import SearchInput from "../search-trials-input";

// private
import styles from "./index.module.scss";

const Filter = ({
  filters,
  categories,
  clearFilters,
  onSearch,
  onUpdate,
  updatedFilters,
}: {
  filters: {
    [key: string]: Array<{ id: string; name: string }>;
  };
  categories: Array<{ id: string; name: string }>;
  clearFilters: () => void;
  onSearch: (e: KeyboardEvent<HTMLInputElement>) => void;
  updatedFilters: string[];
  onUpdate: (updateOption: "toggle", category: string, filter: string, optional?: boolean) => void;
}) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [searchValue, setSearchValue] = useState("");

  return (
    <>
      <div className="d-none d-md-flex justify-content-center mb-md-5">
        <SearchInput onKeyDown={onSearch} setValue={setSearchValue} value={searchValue} />
      </div>
      <div className={cx(styles.filter, "d-none d-md-flex flex-column align-items-center")}>
        <div className={cx(styles["filter-types"], "d-flex")}>
          {categories.map((category) => {
            const uppercaseLetterPosition = category.name.search(/[A-Z]/g);
            const fixedName = category.name.replaceAll(
              /[A-Z]/g,
              ` ${category.name[uppercaseLetterPosition]}`,
            );

            const categoryName = fixedName[0].toUpperCase() + fixedName.substring(1);
            return (
              <div
                key={category.id}
                className={cx(styles.category, "d-flex align-items-center", {
                  [styles.selected]: selectedCategory.name === category.name,
                })}
                onClick={() => setSelectedCategory(category)}
              >
                {/*<div*/}
                {/*  className={cx(styles.icon, "d-flex justify-content-center align-items-center")}*/}
                {/*>*/}
                {/*  <FontAwesomeIcon icon={faDog} />*/}
                {/*</div>*/}
                <h3 className="text-style-h6">{categoryName}</h3>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center w-100">
          <div className="d-flex overflow-x-auto pb-2 mt-4">
            {filters[selectedCategory.name].map((filter) => {
              const isSelected = updatedFilters.includes(`${selectedCategory.id}\v${filter.id}`);
              return (
                <div
                  key={filter.id}
                  className={cx(styles["filter-option"], { [styles.active]: isSelected })}
                  onClick={() => onUpdate("toggle", selectedCategory.id, filter.id, true)}
                >
                  <span className="text-style-body2-regular">{filter.name}</span>
                  {isSelected && <FontAwesomeIcon icon={faTimes} className="ms-2" />}
                </div>
              );
            })}
            <div
              onClick={() => {
                clearFilters();
                setSearchValue("");
              }}
              className="white-space--nowrap px-3 py-2"
            >
              <span className="text-style-body2-bold cursor--pointer">Clear All</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
